.maiCard {
  border: none;
}

.eventCardImg {
  object-fit: cover;
  object-position: center;
}

.EventMainFooter {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.EventSubFooter {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.EventImgStyling {
  object-fit: cover;
  object-position: center;
}

.EventDetailsTitle {
  font-size: 90px;
  font-weight: 700;
  line-height: 117px;
  letter-spacing: 0em;
  text-align: left;
}

.EventDetailsSubTitle {
  font-size: 60px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
}

.eventDetailsButton {
  width: Hug (325px);
  height: Hug (77px);
  padding: 9px, 14px, 9px, 14px;
  gap: 10px;
  font-size: 45px;
  font-weight: 400;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #1c1c1c;
  color: #f3f3f3;
  margin: 3rem 0;
  border: none;
  text-decoration: none;
  padding: 10px;
}

.eventDetailsP {
  font-size: 25px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}

.eventDetailsH1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}

@media (min-width: 768px) {
  .EventMainFooter {
    font-size: 45px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: 0em;
  }

  .EventSubFooter {
    font-size: 45px;
    font-weight: 500;
    line-height: 59px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 600px) {
  .EventDetailsTitle {
    font-size: 30px;
    line-height: 39px;
  }

  .EventDetailsSubTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
  }

  .eventDetailsButton {
    font-size: 15px;
    margin: 2rem 0;
    line-height: 30px;
  }

  .eventDetailsP {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  .eventDetailsH1 {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }
}
