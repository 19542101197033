.shoppingDetails {
  width: 100%;
  background: #ffffff80;
}

.shoppingDetailsTitles {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.shoppingDetailsPrice {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
}

.shoppingDetailsToPay {
  font-size: 25px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: right;
}

.ProceedToCheckout {
  width: 100%;
  height: 50px;
  background: #ffdd64;
  border: none;

  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

.ProceedToCheckoutTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.radioInput {
  width: 30px;
  height: 30px;
  border: 1px;
  accent-color: #1c1c1c;
}

.radioInputText {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 3px;
}

.addressP {
  margin: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.addressDiv {
  background: #ffdd64;
}

.EstimatedDelivery {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}

.EstimatedDeliveryDate {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}

.ProceedToPay {
  width: 100%;
  height: 50px;
  background: #1c1c1c;
  border: none;

  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #f3f3f3;
}

.errorDiv {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background: #ff0000;
  padding: 1rem;
  color: #f3f3f3;
}
