.articleMainDiv {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.articleTitle {
  font-size: 90px;
  font-weight: 700;
  line-height: 117px;
  letter-spacing: 0em;
  text-align: left;
}

.articleUnderTitle {
  font-size: 60px;
  font-weight: 400;
  line-height: 78px;
  letter-spacing: 0em;
  text-align: left;
}

.mainDetails {
  font-size: 45px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
}

.subDetails {
  font-size: 44px;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: left;
}

.ArticleImgDiv {
  overflow: scroll;
  align-items: center;
}

.subText {
  font-size: 25px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}

.textHeader {
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
}

.imgStyling {
  margin-right: 1rem;
  height: 100%;
  width: 361px;
  max-height: 583px;
  cursor: pointer;
}

.photoBy {
  font-size: 15px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: right;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .articleMainDiv {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .articleTitle {
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
  }

  .articleUnderTitle {
    font-size: 18px;
    line-height: 23.4px;
  }

  .mainDetails {
    font-size: 15px;
    font-weight: 700;
    line-height: 19.5px;
  }

  .subDetails {
    font-size: 12px;
    font-weight: 500;
    line-height: 19.5px;
  }

  .subText {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }

  .textHeader {
    font-size: 12px;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }

  .imgStyling {
    max-width: 250px;
    margin-right: 1rem;
  }
}
