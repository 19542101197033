.mainCard {
  width: 92%;
  max-height: 600px;
  padding: 25px 25px 35px 25px;
}
.img {
  width: 100%;
  height: 242px;
  top: 17px;
  left: 20px;
  margin: 0 auto;
  border-radius: 0;
  object-fit: cover;
  object-position: center;
}

.cardBodyArticle {
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 0;
  padding: 9px 3.18px;
}

.cardTitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.cardUnderTitle {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.cardBody {
  margin-top: 2rem;
  border-top: 1px solid #1c1c1c;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.mainFooter {
  font-weight: 700;
}

.subFooter {
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .img {
    height: 250px;
  }

  .mainCard {
    width: 100%;
  }

  .subFooter {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1500px) {
  .img {
    height: 350px;
  }
}
