/* Add this CSS to your stylesheet (e.g., App.css or a dedicated CSS file) */

form {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

form h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.table-container {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

th,
td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

button {
  padding: 0.5rem 1rem;
  margin: 0.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button.edit {
  background-color: #4caf50;
  color: white;
}

button.delete {
  background-color: #f44336;
  color: white;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  margin: 0 0.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
