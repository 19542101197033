/* StoreDescriptionForm.css */

form {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

form h2 {
  text-align: center;
  margin-bottom: 1rem;
}

form section {
  margin-bottom: 1rem;
}

form h3 {
  margin-bottom: 0.5rem;
}

form label {
  display: block;
  margin-bottom: 0.5rem;
}

form textarea {
  width: 100%;
  height: 100px;
  padding: 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.storeform button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

form button:hover {
  background-color: #0056b3;
}

/* Add this for Arabic section */
.arabic-section {
  direction: rtl;
  text-align: right;
}
