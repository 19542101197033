.headText {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  padding: 0 1rem;
}

.paragraphText {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  padding: 0 1rem;
}

.partner-logo-dev {
  display: flex;
  justify-content: center;
  gap: 4rem;
  max-width: 85%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.partner-logo {
  width: 55px;
  height: auto;
  transition: transform 0.2s ease-in-out;
}

.partner-logo:hover {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .headText {
    font-weight: 700;
    font-size: 90px;
    line-height: 117px;
    padding: 0;
  }

  .paragraphText {
    font-weight: 400;
    font-size: 25px;
    line-height: 46px;
    padding: 0;
  }

  .partner-logo {
    width: 100px;
  }
}
