.storeP {
  font-size: 50px;
  font-weight: 400;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
  margin: 6rem 0;
}

@media only screen and (max-width: 600px) {
  .storeP {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin: 2rem 0;
  }
}
