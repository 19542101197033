.underLineTextStyling {
  font-weight: 400;
  font-size: 18px;
  line-height: 23.4px;
}

.underLineBoldTextStyling {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
}

.aboutUs {
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  text-align: right;
}

.fromThePeopleTextStyling {
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
}

.mainNavBar {
  display: none;
}

.mainPhoneNavBar {
  display: block;
  position: fixed;
  width: 100%;
  z-index: 333;
  padding: 0 0.7rem;
}
.openedMenu {
  padding: 0 0.7rem;
}

.phoneTextSpan {
  font-size: 1.5rem;
  height: 2rem;
}

.navbarDivEng {
  padding: 1.5rem;
  padding-left: 0;
}

.navbarDivAr {
  padding: 1.5rem;
  padding-right: 0;
}

@media (min-width: 845px) {
  .mainNavBar {
    display: block;
  }
  .mainPhoneNavBar {
    display: none;
  }
}
