.ArchiveMaiDiv {
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #1c1c1c;
  cursor: pointer;
}

.ArchiveMaiDiv:first-child {
  padding-top: 0;
}

.EventDetailsImg {
  margin-right: 4rem;
  height: auto;
  width: 200px;
  max-height: 583px;
  cursor: pointer;
}

.EventDetailsImg:last-child {
  margin-right: 0;
}

.EventDetailsHeader {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
}
.EventDetailsSubHeader {
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}

.youtubeLogo {
  position: absolute;
  top: 50%;
  left: calc(50% - 2rem);
  transform: translate(-50%, -50%);
  background-color: #fff;
}

@media (min-width: 768px) {
  .EventDetailsImg {
    margin-right: 4rem;
    height: auto;
    width: 361px;
    max-height: 583px;
    cursor: pointer;
  }
}
