.productDetailsMainDiv {
}

.productsYouMightLike {
  width: 200px;
  cursor: pointer;
}

.productDetailsImgDiv {
  justify-content: start;
  align-items: center;
  width: 60%;
}

.productDetailsDiv {
  width: "40%";
  flex-grow: 1;
}

.productName {
  font-size: 23px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.designedBy {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.designersDetails {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.moreProducts {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.mainColorDiv {
  margin-right: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .productDetailsMainDiv {
    flex-direction: column;
  }

  .productDetailsImgDiv {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .productDetailsDiv {
    width: "100%";
  }

  .productName {
    font-size: 15px;
    line-height: 19.5px;
  }

  .designedBy {
    font-size: 9px;
    line-height: 11.7px;
  }

  .designersDetails {
    font-size: 9px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  .mainColorDiv {
    margin-right: 0.3rem;
  }

  .productsYouMightLike {
    width: 110px;
  }
}
