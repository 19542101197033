.productMainDiv {
  width: 90%;
  margin: 0 2rem 3rem 0;
}

.productMainDiv:hover .productImage {
  transform: scale(1.2);
  /* Increase the scale on hover (you can adjust the value) */
}

.productImage {
  width: 90%;
  transition: transform 0.6s ease-in-out;
  margin: 0 auto;
  cursor: pointer;
}

.productHeader {
  font-size: 23px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.productSubHeader {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.ColorAndSize {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.colorDiv {
  width: 24px;
  height: 24px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: "1rem";
  cursor: pointer;
}

.SizeDiv {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  border-radius: 50%;
  border: 1px solid #1c1c1c;
  font-family: Quicksand;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.priceDiv {
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 1rem;
}

.TaxDiv {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.productCounts {
  width: 129px;
  height: 32px;
  background-color: #ffdd64;
}

.productCountsButton {
  border: none;
  background-color: #ffdd64;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c1c1c;
}

.productAddToCart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 32px;
  background-color: #1c1c1c;
  font-size: 15px;
  color: #efead1;
  text-decoration: none;
  border-radius: 0.3rem;
}

.productAddToCart:hover {
  background-color: #313131;
}
.productCountsButton:hover {
  background-color: #fad85e;
}

@media only screen and (max-width: 600px) {
  .productMainDiv {
    width: 100%;
    margin: 0 0 3rem 0;
  }

  .productHeader {
    font-size: 15px;
    line-height: 19.5px;
  }

  .productSubHeader {
    font-size: 9px;
    line-height: 11.7px;
  }

  .productImage {
    width: 100%;
  }

  .ColorAndSize {
    font-size: 10px;
    line-height: 13px;
  }

  .colorDiv {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .SizeDiv {
    width: 15px;
    height: 15px;
  }

  .priceDiv {
    font-size: 15px;
    line-height: 19.5px;
  }

  .productAddToCart {
    width: 70px;
    font-size: 7px;
    line-height: normal;
  }

  .productCounts {
    width: 70px;
    height: 24px;
    font-size: 8px;
    background-color: #ffdd64;
  }
}
