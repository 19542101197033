/* AddArchive.css */
.images-preview {
  display: flex;
  gap: 10px;
}

.image-preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.remove-button {
  background: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtl {
  direction: rtl;
  text-align: right;
}
