.TextStyling {
  font-weight: 400;
  font-size: 40px;
  line-height: 35px;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 1rem;
  color: #000;
}

.mainSideNavBar {
  display: none;
}

.cartQuantityClass {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

@media (min-width: 821px) {
  .mainSideNavBar {
    display: flex;
  }
}

@media (max-height: 700px) {
  .TextStyling {
    font-size: 35px;
    line-height: 20px;
  }
}
